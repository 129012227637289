import { useEffect } from 'react';
import { navigate } from 'gatsby';

const OurWork = () => {
  useEffect(() => {
    navigate(`/our-company/news/page-1`);
  }, []);
  return '';
};

export default OurWork;
